<template>
	<div>
		<section class="section is-main-section" :class="{ 'p-0': modal }">
			<card-component
				:title="
					$route.params.id ? `${form.name} Düzenle` : 'Banka Oluştur'
				"
				icon="domain"
			>
				<form @submit.prevent="save">
					<b-field grouped group-multiline>
						<b-field expanded label="İsim">
							<b-input
								size="is-small"
								v-model="form.name"
								icon="domain"
								placeholder="İsim"
								name="name"
								required
							/>
						</b-field>
						<b-field expanded label="Code">
							<b-input
								size="is-small"
								v-model="form.code"
								icon="domain"
								placeholder="Kod"
								name="Kod"
								required
							/>
						</b-field>
						<b-field expanded label="Logo">
							<b-input
								size="is-small"
								v-model="form.logo"
								icon="domain"
								placeholder="Logo"
								name="logo"
								required
							/>
						</b-field>
					</b-field>
					<hr />
					<div class="is-flex is-justify-content-end is-flex-grow-1">
						<div class="control mr-2">
							<b-button
								native-type="submit"
								type="is-primary"
								size="is-small"
							>
								{{ $route.params.id ? "Güncelle" : "Ekle" }}
							</b-button>
						</div>
						<div class="control">
							<b-button
								type="is-primary is-outlined"
								size="is-small"
								@click="modal ? $emit('close') : $router.go(-1)"
								>Geri Dön</b-button
							>
						</div>
					</div>
				</form>
			</card-component>
		</section>
	</div>
</template>

<script>
	import mapValues from "lodash/mapValues";
	import CardComponent from "@/components/CardComponent";
	import { BankListService as Service } from "@/services";

	export default {
		name: "BankCreateOrUpdate",
		components: { CardComponent },
		props: ["modal"],
		data() {
			return {
				isLoading: false,
				form: {
					name: null,
					logo: null,
					code: null
				},
				bankAccounts: [],
			};
		},
		mounted() {
			if (this.$route.params.id) this.getData();
		},
		methods: {
			getData() {
				Service.edit(this.$route.params.id)
					.then(({ data }) => (this.form = data))
					.catch((err) =>
						this.$buefy.toast.open({
							message: err.message,
							position: "is-top-right",
							type: "is-danger",
						})
					);
			},
			save() {
				Service[this.$route.params.id ? "update" : "create"](this.form)
					.then(() => {
						this.$buefy.toast.open({
							message: this.$route.params.id
								? "Güncellendi"
								: "Eklendi",
							type: "is-success",
						});
						this.reset();
						if (this.modal) this.$emit("close");
						else this.$router.go(-1);
					})
					.catch((err) =>
						this.$buefy.toast.open({
							message: err.message,
							position: "is-top-right",
							type: "is-danger",
						})
					);
			},
			reset() {
				this.form = mapValues(this.form, (item) => {
					if (item && typeof item === "object") {
						return [];
					}
					return null;
				});
			},
		},
	};
</script>
